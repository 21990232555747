import {dateUtil} from "./date-util";

export const marketplaceOrderHelper = {
    getExpireTime
};

function getExpireTime(marketplaceOrder) {
    if (!marketplaceOrder) {
        return ""
    }

    return marketplaceOrder.canExpire
        ? dateUtil.asDateTimeNormal(marketplaceOrder.currentPeriodEnd)
        : "Na stałe"
}